import React from "react";
import './App.scss';
import Header from "../../components/Header/Header";
import Title from "../Title/Title";
import ExefixBanner from "../../components/ExefixBanner/ExefixBanner";
import SpeedTab from "../../components/SpeedTab/SpeedTab";
import SpeedTestResult from "../../components/SpeedTestResult/SpeedTestResult";
import SpeedPlan from "../../components/SpeedPlan/SpeedPlan";
import SpeedTestFaq from "../../components/SpeedTestFaq/SpeedTestFaq";
import Footer from "../../components/Footer/Footer";

function App() {
    return (
        <>
            <Header />
            <main>
                <Title />
                <ExefixBanner />
                <SpeedTab />
                <SpeedTestResult />
                <SpeedPlan />
                <SpeedTestFaq />
            </main>
            <Footer />
        </>
    );

}

export default App;
