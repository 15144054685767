import { Container } from "react-bootstrap";
import styles from './Title.module.scss';

const Title = () => {
    return (
        <Container className={styles.container}>
            <h1 className="color-white text-center">Exetel speed test</h1>
            <p className="color-white text-center mt-lg-5 mt-2 mb-3">
            The speed test is currently offline for a tune-up. We’ll be back soon to help you check your speeds! In the meantime, you can head to <a href="https://speedtest.net" target="_blank" className="regular">speedtest.net</a> to check your speed. 
            </p>
        </Container>
    );
};
export default Title;
